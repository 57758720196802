import Head from "next/head";

import { ProjectLogo } from "server/schemas/projects";

import { PageWrapper } from "components/Page/Page";

import { ProjectLogoDisplay } from "components/ProjectLogoDisplay";
import { useRouter } from "next/router";

import { Fragment, useEffect, useId, useRef, useState } from "react";
import toast from "react-hot-toast";
import * as z from "zod";
import {
  ActiveProjectAuthType,
  LoginForm,
} from "block-system/components/LoginForm";
import { ProjectAuthWrapper } from "./presentational";

type Props = {
  projectId: string;
  projectLogo: ProjectLogo;
  stytchOrganizationId: string | null | undefined;
  authType: ActiveProjectAuthType;
  onAuthSuccess: () => void;
  captchaEnabled: boolean;
};

export function ProjectAuthPage({
  projectId,
  projectLogo,
  authType,
  stytchOrganizationId,
  onAuthSuccess,
  captchaEnabled,
}: Props) {
  return (
    <Fragment>
      <ProjectAuthWrapper>
        <Head>
          <title>Log in</title>
        </Head>
        <PageWrapper $isEditing={false}>
          <ProjectLogoDisplay logo={projectLogo} />
          <LoginForm
            stytchOrganizationId={stytchOrganizationId}
            projectId={projectId}
            captchaEnabled={captchaEnabled}
            authType={authType}
            onAuthSuccess={onAuthSuccess}
            isEditing={false}
          />
        </PageWrapper>
      </ProjectAuthWrapper>
      <ServerAuthErrorToast />
    </Fragment>
  );
}

const QueryParametersSchema = z.object({
  error: z.literal("true"),
});

export function ServerAuthErrorToast() {
  const router = useRouter();
  const initialQueryParamsRef = useRef(router.query);

  const toastId = useId();

  /**
   * Purely for tests purposes.
   */
  const [test_resolved, test_setResolved] = useState(false);

  useEffect(() => {
    const cleanup = () => {
      test_setResolved(true);
      initialQueryParamsRef.current = {};
    };

    const parseQueryResult = QueryParametersSchema.safeParse(
      initialQueryParamsRef.current
    );

    if (!parseQueryResult.success) {
      cleanup();
      return;
    }

    toast.error("There was an error with your login. Please try again.", {
      id: toastId,
      duration: Infinity,
    });

    const url = new URL(router.asPath, window.location.origin);
    url.searchParams.delete("error");
    /**
     * We could not achieve the same with `router.replace`.
     * `router.replace({query: {...router.query, error: null}})` produced malformed URL.
     * `router.replace({...router, query: {...router.query, error: null}})` also produced malformed URL.
     * `router.replace("CORRECT_PATH")` refreshes the page.
     * `window.history.replaceState` updates the URL state, but then the `router.query` is "behind" (still has the `error` query parameter).
     */
    void router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          error: undefined,
        },
      },
      "/",
      { shallow: true }
    );

    cleanup();
  }, [router, toastId]);

  /**
   * We have to know when the toast has been resolved, so we can assert on it.
   * If we did not have this state, the test that assert that the toast WAS NOT called
   * could pass even if the toast was called (as the assertion would be made before useEffect resolved).
   */
  return (
    <div data-testid="server-auth-error-toast" data-resolved={test_resolved} />
  );
}
