import { ProjectAuthType } from "@prisma/client";
import { ProjectAuthPage } from "components/ProjectAuth/ProjectAuthPage";
import { ProjectError } from "components/PublishedPageSSR/ProjectError";
import { checkFeature } from "lib/hooks/useFeatureAccessCheck";
import { projectForPublishedLoginPageSchema } from "server/schemas/projects";
import { z } from "zod";

type ProjectForPublishedPage = z.infer<
  typeof projectForPublishedLoginPageSchema
>;

export function AuthPage({
  project,
  refetch,
}: {
  project: ProjectForPublishedPage;
  refetch: VoidFunction;
}) {
  const creator = {
    ...project.creator,
    paidFeatureAccess: project?.paidFeatureAccess,
  };
  const hasManagedUsersAccess = checkFeature("managedUsers", creator);
  const hasPasswordProtectAccess = checkFeature("passwordProtect", creator);
  const hasStytchAuthAccess = checkFeature("stytchAuth", creator);

  const hasAccessToAnyAuthFeature =
    hasManagedUsersAccess || hasPasswordProtectAccess || hasStytchAuthAccess;

  const isLackingUpgrade =
    project.authType !== ProjectAuthType.None && !hasAccessToAnyAuthFeature;
  if (isLackingUpgrade) {
    return (
      <ProjectError
        title="Project cannot be rendered"
        message="Project access settings are enabled for your account, but your account is not upgraded; please consider upgrading your account or clear access settings to view the project."
      />
    );
  }

  if (project.authType === "None") {
    throw new Error("Consumer is not authorized, but the project has no auth.");
  }

  return (
    <ProjectAuthPage
      stytchOrganizationId={project.stytchOrganizationId}
      projectId={project.id}
      captchaEnabled={project.captchaEnabled}
      projectLogo={project.logo}
      authType={project.authType}
      onAuthSuccess={() => {
        void refetch();
      }}
    />
  );
}
