/* eslint-disable @next/next/no-img-element */
import { styled } from "lib/theme";
import { ProjectLogo } from "server/schemas/projects";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

export function ProjectLogoDisplay({ logo }: { logo: ProjectLogo }) {
  if (!logo || !logo.showLogoOnLoginPage) return null;

  return (
    <LogoWrapper>
      <img
        src={logo.url}
        alt={logo.name}
        height={logo.height ?? 40}
        width="auto"
      />
    </LogoWrapper>
  );
}
